import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BookView } from "../../components/core-sub/BookView";
import { useCore } from "../../components/core-sub/context";
import { Book } from "../../components/core-sub/Controller/book";

export const PagePreView = () => {
  const { bookId } = useParams();
  const { user, t } = useCore();
  const [state, setState] = useState<{
    loading: boolean;
    restrict?: boolean;
    doc?: Book;
  }>({
    loading: true,
  });

  useEffect(() => {
    if (user.loading === false && user.data && bookId) {
      Book.getOne(user.data, bookId, true).then((doc) => {
        setState((s) => ({ ...s, loading: false, doc }));
      });
    }
  }, [user, bookId]);

  return (
    <BookView
      back="/"
      loading={state.loading}
      value={state.doc}
      posts={state.doc?.posts ?? {}}
      breadcrumbs={[{ label: t("Home"), to: "/" }]}
    />
  );
};
