import { Link } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAlerts } from "../../components/core-sub/Alerts";
import { BookEdit } from "../../components/core-sub/BookEdit";
import { useCore } from "../../components/core-sub/context";
import { Book } from "../../components/core-sub/Controller/book";

export const PageEditor = () => {
  const { t, user } = useCore();
  const { bookId } = useParams();
  const [state, setState] = useState<{
    loading: boolean;
    restrict?: boolean;
    data?: Book | null;
  }>({
    loading: true,
  });
  const { addAlert } = useAlerts()
  const nav = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (user.loading === false && user.data && bookId) {
      if(location.state?.data){
        const data = new Book(location.state.data)
        setState((s) => ({ ...s, loading: false, restrict: false, data }))
      } else {
        Book.getOne(user.data, bookId)
          .then((data) => {
            setState((s) => ({ ...s, loading: false, restrict: false, data }));
          })
          .catch(() =>
            setState((s) => ({ ...s, loading: false, restrict: true }))
          );
      }
    }
  }, [bookId, user, location.state]);

  const handleSave = async () => {
    if(state.data){
      await state.data.save()
      addAlert({ label: t("Saved") })
    }
  }
  const handlePreview = () => {
    if(state.data && bookId){
      const newState = { data:state.data.data(), back: `/edit/${bookId}` }
      nav(`/preview`, {state:newState})
    }
  }

  return state.data ? (
    <BookEdit
      onPreview={handlePreview}
      containerProps={{
        loading: state.loading,
        restrict: state.restrict,
        restrictProps: {
          message: t("$Name Not Found", { name: t("BOOK") }),
          link: "/",
        },
      }}
      breadcrumbs={[
        {
          label: t("Home"),
          component: <Link href="https://mek.network">{t("Home")}</Link>,
        },
      ]}
      data={state.data}
      setData={(data) => setState((s) => ({ ...s, data }))}
      onSave={handleSave}
    />
  ) : null;
};
