import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container } from "../../components/core-sub/Container";
import { ContentHeader } from "../../components/core-sub/ContentHeader";
import { useCore } from "../../components/core-sub/context";
import { MainStatic } from "../../components/core-sub/Controller";
import { Book } from "../../components/core-sub/Controller/book";
import { DataGrid } from "../../components/core-sub/DataGrid";
import { KuiButton } from "../../components/core-sub/KuiButton";
import MainContainer from "../../components/core-sub/MainContainer";
import { usePopup } from "../../components/core-sub/Popup";
import {
  VisibilityTabsValue,
  VisibilityTabs,
} from "../../components/core-sub/VisibilityTabs";
import { genColumn } from "../../components/core-sub/DataGrid/gen.column";

export const PageManage = () => {
  const { user, t } = useCore();
  const [tab, setTab] = useState<VisibilityTabsValue>("private");
  const [state, setState] = useState<{
    loading: boolean;
    docs: Book[];
  }>({
    loading: true,
    docs: [],
  });
  const { Popup } = usePopup();
  const nav = useNavigate();

  const handleCreate = () => {
    Popup.prompt({
      title: t("Create $Name", { name: t("Book") }),
      text: t("$Name Name", { name: t("Book") }),
      icon: "plus-circle",
      onConfirm: async (value) => {
        if (value && user.data) {
          const doc = await Book.add(user.data, value);
          setTab("private");
          Popup.confirm({
            title: t("Added"),
            text: t("Ready To Edit", { name: value }),
            icon: "check-circle",
            onConfirm: () => {
              nav(`/edit/${doc.id}`);
            },
          });
        }
      },
    });
  };
  const handleRestore = (doc: Book) => async () => {
    await doc.update("visibility", "private");
  };
  const handleRemove = (doc: Book) => async () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        if (user.data) {
          await doc.update("visibility", "trash");
          setTab("trash");
          Popup.alert({
            title: t("Removed"),
            text: t("$Name Has Removed", { name: doc.title }),
            icon: "check-circle",
          });
        }
      },
    });
  };
  const handleRemoveForever = (doc: Book) => async () => {
    Popup.remove({
      title: t("Remove"),
      text: t("Do You Want To Remove $Name Forever", { name: doc.title }),
      icon: "trash",
      onConfirm: async () => {
        if (user.data) {
          await doc.remove();
          Popup.alert({
            title: t("Removed"),
            text: t("$Name Has Removed", { name: doc.title }),
            icon: "check-circle",
          });
        }
      },
    });
  };

  useEffect(() => {
    if (user.loading === false && user.data) {
      return Book.watch(user.data, (docs) => {
        setState((s) => ({ ...s, docs, loading: false }));
      });
    } else {
      setState((s) => ({ ...s, docs: [], loading: true }));
    }
  }, [user]);

  return (
    <MainContainer signInOnly>
      <Container maxWidth="md">
        <ContentHeader
          label={t("Manage $Name", { name: t("Book") })}
          breadcrumbs={[
            {
              label: t("Home"),
              to: "/",
            },
            { label: t("Manage $Name", { name: t("Book") }) },
          ]}
          actions={<KuiButton tx="add" onClick={handleCreate} />}
        />
        <VisibilityTabs value={tab} onChange={setTab} items={state.docs} />
        <DataGrid
          initialState={genColumn.init()}
          loading={state.loading}
          rows={MainStatic.docsFilter(state.docs, tab)}
          columns={[
            genColumn.action<Book>({
              onEdit: (row) => nav(`/edit/${row.id}`, { state: { data: row } }),
              onRemove: (row) => handleRemoveForever(row)(),
              onRestore: (row) => handleRestore(row)(),
              onRemoveTrash: (row) => handleRemove(row)(),
            }),
            genColumn.feature(),
            genColumn.title(t, `/preview`),
            genColumn.date(t),
          ]}
        />
      </Container>
    </MainContainer>
  );
};
