import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackWState } from "../../components/core-sub/BackWState";
import { BookView } from "../../components/core-sub/BookView";
import { Book } from "../../components/core-sub/Controller/book";
import { Post } from "../../components/core-sub/Controller/post";
import { Loading } from "../../components/core-sub/Loading";

export const PageEditPreview = () => {
  const location = useLocation();
  const [state, setState] = useState<{
    back: string;
    data: null | Book;
    posts: Record<string, Post>;
  }>({
    back: "",
    data: null,
    posts: {},
  });
  const nav = useNavigate();

  const handleBack = () => {
    if (state.data) {
      nav(state.back, { state: { data: state.data.data() } });
    }
  };

  useEffect(() => {
    if (location.state.data && location.state.back) {
      const book = new Book(location.state.data);
      book.getPosts().then((posts) => {
        setState((s) => ({
          ...s,
          back: location.state.back,
          data: new Book(location.state.data),
          posts,
        }));
      });
    }
  }, [location.state]);

  return state.data ? (
    <BookView
      back={<BackWState onClick={handleBack} />}
      value={state.data}
      posts={state.posts}
    />
  ) : (
    <Loading />
  );
};

export default PageEditPreview;
