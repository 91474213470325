import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { PageNotFound } from "./pages/not.found";
import { PageManage } from "./pages/manage";
import { PageEditor } from "./pages/edit";
import { PagePreView } from "./pages/preview";
import { AppMenu, getAppMenu } from "./components/core-sub/app.menu";
import CoreProvider from "./components/core-sub/context";
import { app } from "./components/core-sub/Controller/firebase";
import PageEditPreview from "./pages/edit.preview";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/preview/:bookId" element={<PagePreView />} />
        <Route path="/preview/" element={<PageEditPreview />} />
        <Route path="/edit/:bookId" element={<PageEditor />} />
        <Route path="/" element={<PageManage />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

function App() {
  const [appMenu, setAppMenu] = useState<AppMenu[]>([]);

  useEffect(() => {
    getAppMenu(app).then(setAppMenu);
  }, []);
  return (
    <CoreProvider
      appMenu={appMenu}
      firebaseApp={app}
      sitename="BOOK"
      logo={process.env.REACT_APP_LOGO}
    >
      <Routing />
    </CoreProvider>
  );
}

export default App;
